// snippet: rcep
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import styles from './AdminSettingForm.styles';
import AdminSettingFormControls from './AdminSettingFormControls';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import axios from 'axios';
import { initialSettings } from '../../../reducers/data';
import { updateData, initialData } from '../../../api/common/actions';
import { readSettings } from '../../../helpers/board';
import box_type_enum from '../../Common/box_type_enum';

export class AdminSettingForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      retryAttempts: 0,
      maxRetries: 3
    };
  }
  handleBack = () => {
    this.props.history.push('/RearStock/AdminPanel');
  };

  onDataSourceChange = async (event, status) => {
    await this.getBoxesData(status);
  };

  getBoxesData = async (status) => {
    const { retryAttempts, maxRetries } = this.state;
    let boxId;
    if (window.electron) {
      const fileData = JSON.parse(readSettings());
      boxId = fileData ? fileData.boxId : '';
    } else {
      boxId = localStorage.getItem('boxId');
    }
    if (status === false || status === null) {
      this.props.initialData(initialSettings);
      return true;
    } else {
      const token = localStorage.getItem('authTokens');
      const actualToken = atob(token);
      if (![undefined, 'undefined', null, null, ''].includes(boxId)) {
        return await axios
          .get(`${process.env.REACT_APP_BASEURL}getprescriptions/${boxId}`, {
            headers: {
              Authorization: 'Bearer ' + actualToken,
              'Content-Type': 'application/json'
            }
          })
          .then((response) => {
            if (
              response &&
              response.data &&
              response.data.box &&
              response.data.box.id
            ) {
              this.props.updateData(response.data);
            }
            if (
              response &&
              response.data &&
              response.data.box &&
              response.data.box.type_id === box_type_enum.FASTLANE
            ) {
              localStorage.setItem('boxType', response.data.box.type_id);
            }
            this.props.updateData(response.data);
            return response.data;
          })
          .catch((error) => {
            if (retryAttempts < maxRetries) {
              this.setState(
                { retryAttempts: retryAttempts + 1 },
                this.getBoxesData()
              );
            } else {
              console.log(error);
            }
          });
      }
    }
  };

  render() {
    const { classes, patients } = this.props;
    return (
      <div className={classNames(classes.container)}>
        <div className={classNames(classes.form)}>
          <AdminSettingFormControls
            patients={patients}
            handleBack={this.handleBack}
            onDataSourceChange={this.onDataSourceChange}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ box, pollingStats }) => ({
  box,
  ...pollingStats
});

const mapDispatchToProps = (dispatch) => ({
  updateData: (box) => dispatch(updateData(box)),
  initialData: (box) => dispatch(initialData(box))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(styles, { withTheme: true })(
    injectIntl(withRouter(AdminSettingForm))
  )
);
