const criticalErrors = require('./criticalErrors').default;

export function customFetch(
  url,
  options,
  funcName,
  operation,
  kiosk_box_id,
  isPreventive
) {
  return new Promise((resolve, reject) => {
    console.log('Fetching:', { url, options, funcName, kiosk_box_id });
    const isSafeMode = localStorage.getItem('safemode');
    const isUserLogin = localStorage.getItem('user-login');
    if (
      [false, 'false'].includes(isUserLogin) &&
      [true, 'true'].includes(isSafeMode)
    ) {
      let objRej = {
        safeModeResponse: {
          Command: funcName,
          Status: 'Error',
          StatusMessage: 'Command blocked due to safe mode.',
          NextCommand: 'None'
        }
      };
      const wrappedResponse = {
        json: async () => objRej
      };
      reject(wrappedResponse);
    }
    if (
      [false, 'false'].includes(isSafeMode) ||
      [true, 'true'].includes(isUserLogin)
    ) {
      fetch(url, options)
        .then((response) => {
          try {
            return response.json();
          } catch (error) {
            return null;
          }
        })
        .then(async (response) => {
          let theResponse = null;
          if (['reset-festo', 'load-bin'].includes(funcName)) {
            theResponse = response.data;
          } else {
            theResponse = response.data[Object.keys(response.data)[0]];
          }
          console.log(
            `Response from ${funcName} was ${JSON.stringify(response, null, 2)}`
          );
          if (theResponse.hasOwnProperty('Status')) {
            if (
              ['Ok', 'ok', 'Busy', 'busy'].includes(theResponse.Status) ||
              isPreventive
            ) {
              if (
                ['Ok', 'ok', 'Busy', 'busy'].includes(theResponse.Status) &&
                isPreventive
              ) {
                const payload = {
                  kiosk_box_id,
                  error_message: `Preventive operation success was unexpected.`,
                  operation: operation,
                  isCritical: false
                };
                await reportError(payload);
              }

              const wrappedResponse = {
                json: async () => response
              };
              resolve(wrappedResponse);
            } else {
              const _isCritical = isCritical(theResponse.StatusMessage);
              if (_isCritical) {
                // checking report only flag explicitly the flag.
                const isReportOnly = localStorage.getItem('report-only');
                if (
                  [
                    undefined,
                    'undefined',
                    null,
                    'null',
                    '',
                    false,
                    'false'
                  ].includes(isReportOnly)
                ) {
                  // Put under maintenance.
                  if ([false, 'false'].includes(isUserLogin)) {
                    localStorage.setItem('safemode', true);
                    window.location.href = '/safe-mode';
                  } else if ([false, 'false'].includes(isSafeMode)) {
                    localStorage.setItem('safemode', true);
                    window.location.href = '/safe-mode';
                  }
                }
                // Report error.
                const payload = {
                  kiosk_box_id,
                  error_message: theResponse.StatusMessage,
                  operation: operation,
                  isCritical: _isCritical
                };
                await reportError(payload);
                const wrappedResponse = {
                  json: async () => response
                };
                reject(wrappedResponse);
              } else {
                // Report error on each API call.
                const payload = {
                  kiosk_box_id,
                  error_message: theResponse.StatusMessage,
                  operation: operation,
                  isCritical: false
                };
                await reportError(payload);
                const wrappedResponse = {
                  json: async () => response
                };
                resolve(wrappedResponse);
              }
            }
          } else {
            const payload = {
              kiosk_box_id,
              error_message: `Unknown error while sending command.`,
              operation: operation,
              isCritical: false
            };
            await reportError(payload);
            const wrappedResponse = {
              json: async () => response
            };
            reject(wrappedResponse);
          }
        })
        .catch(async (error) => {
          const payload = {
            kiosk_box_id,
            error_message: error.message,
            operation: operation,
            isCritical: false
          };
          await reportError(payload);
          const wrappedResponse = {
            json: async () => error
          };
          reject(wrappedResponse);
        });
    } else {
    }
  });
}

const isCritical = (message) => {
  return criticalErrors.map((x) => message === x.message).length > 0
    ? true
    : false;
};

const reportError = async (payload) => {
  const token = localStorage.getItem('authTokens');
  const actualToken = atob(token);
  fetch(process.env.REACT_APP_BASEURL + 'fastlane/report', {
    method: 'POST',
    withCredentials: true,
    headers: {
      Authorization: 'Bearer ' + actualToken
    },
    body: JSON.stringify(payload)
  })
    .then((response) => {
      try {
        return response.text();
      } catch (error) {
        return null;
      }
    })
    .then((uData) => {
      console.log(`Error reported successfully!`);
    })
    .catch((error) => {
      console.log('Error while sending report ', JSON.stringify(error));
    });
};
