import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import classNames from 'classnames';
import { Formik } from 'formik';
import styles from './OktaLoginForm.styles';
import OktaLoginFormControls from './OktaLoginFormControls';
import { injectIntl, defineMessages } from 'react-intl';
import { withRouter } from 'react-router';
import { KioskFooter, KioskIdelScreen } from '..';
import spinner from './images/spinner.gif';
import AdminHeader from '../AdminLayout/AdminHeader';
import * as Yup from 'yup';

import '@okta/okta-auth-js/polyfill';
import { OktaAuth } from '@okta/okta-auth-js';

import uuidGenerator from '../Common/uuidGenerator';
const Cryptr = require('cryptr');
const cryptr = new Cryptr('iLocalBox');
const _cryptr = new Cryptr(process.env.REACT_APP_SESSION_CIPHER);

const messages = defineMessages({
  EnterEmail: {
    id: 'Admin.Login.EnterEmail',
    defaultMessage: 'Enter your email address'
  },
  EnterValidEmail: {
    id: 'Admin.Login.EnterValidEmail',
    defaultMessage: 'Enter a valid email address'
  },
  EmailRequired: {
    id: 'Admin.Login.EmailRequired',
    defaultMessage: 'Email address is required'
  },
  EnterPassword: {
    id: 'Admin.Login.EnterPassword',
    defaultMessage: 'Enter your password'
  },
  PasswordEightChar: {
    id: 'Admin.Login.PasswordEightChar',
    defaultMessage: 'Password must contain at least 8 characters'
  },
  PasswordOneUpper: {
    id: 'Admin.Login.PasswordOneUpper',
    defaultMessage: 'Password must contain at least one uppercase character'
  },
  PasswordOneLower: {
    id: 'Admin.Login.PasswordOneLower',
    defaultMessage: 'Password must contain at least one lowercase character'
  },
  PasswordOneNumber: {
    id: 'Admin.Login.PasswordOneNumber',
    defaultMessage: 'Password must contain at least one numeric character'
  },
  PasswordRequired: {
    id: 'Admin.Login.PasswordRequired',
    defaultMessage: 'Password is required'
  }
});

export class OktaLoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxlength: 100,
      isLoader: false,
      usernameError: false,
      passwordError: false,
      nameError: false,
      user: null,
      NEW_PASSWORD_REQUIRED: false,
      forgotPasswordSMSFlow: false,
      userid: ''
    };
    this.authClient = window.oktaAuthClient;
    this.username = null;
  }

  componentDidMount() {
    this.auth();
  }

  handleback = () => {
    setTimeout(() => {
      this.props.history.push('/SecureBadgeLogin');
    }, 30);
  };
  binduser = (data) => {
    this.username = data;
  };

  auth = async (username, password, issuer, clientId) => {
    const config = {
      pkce: true,
      devMode: true,
      scopes: ['openid', 'profile', 'email'],
      disableHttpsCheck: true,
      redirectUri: process.env.REACT_APP_REDIRECT_URI
    };

    issuer
      ? (config.issuer = issuer)
      : (config.issuer = sessionStorage.getItem('issuer'));
    clientId
      ? (config.clientId = clientId)
      : (config.clientId = sessionStorage.getItem('clientId'));

    if (window.location.href.match('implicit/callback')) {
      if (!config.issuer && username) {
        fetch(process.env.REACT_APP_BASEURL + 'auth/oidc/' + username, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
          .then((response) => {
            try {
              return response.json();
            } catch (error) {
              return null;
            }
          })
          .then((uData) => {
            if (uData.success) {
              config.issuer = uData.issuer || uData.parentAccount.issuer;
              config.clientId = uData.clientId || uData.parentAccount.client_id;
            }
          })
          .catch((error) => {
            if (error && error.response) {
              console.error(error.response);
            }
          });
      } else if (
        !config.issuer &&
        !username &&
        window.location.href.match('iss=(.+)')
      ) {
        const issuerFromQueryString = decodeURIComponent(
          window.location.href.match('iss=(.+)')[1]
        );
        const response = await fetch(
          process.env.REACT_APP_BASEURL +
            'auth/oidc/issuer/' +
            encodeURIComponent(issuerFromQueryString),
          {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
          }
        ).then((response) => {
          try {
            return response.json();
          } catch (error) {
            return null;
          }
        });
        if (response.success) {
          config.issuer = response.issuer;
          config.clientId = response.clientId;
          sessionStorage.setItem('issuer', response.issuer);
          sessionStorage.setItem('clientId', response.clientId);
        }
      }
    }

    if (!config.issuer && sessionStorage.getItem('issuer')) {
      config.issuer = sessionStorage.getItem('issuer');
      config.clientId = sessionStorage.getItem('clientId');
    }

    if (!config.issuer) {
      return;
    }

    // initiating OktaAuth instance
    if (!this.authClient) {
      this.authClient = new OktaAuth(config);
      window.oktaAuthClient = this.authClient;
    }
    if (this.authClient && this.authClient.isLoginRedirect()) {
      // Parse token from redirect url
      const data = await this.authClient.token.parseFromUrl();
      const { idToken } = data.tokens;
      this.authClient.tokenManager.setTokens(data.tokens);
      sessionStorage.setItem('jwtToken', JSON.stringify(idToken.idToken));
      sessionStorage.setItem(
        'user',
        JSON.stringify({
          signInUserSession: {
            idToken: {
              jwtToken: idToken.idToken,
              payload: {
                ...idToken.claims
              }
            }
          },
          username: idToken.claims.email
        })
      );
      await this.getOktaUser();
    } else {
      // Attempt to retrieve ID Token from Token Manager
      if (this.authClient) {
        const idToken = await this.authClient.tokenManager.get('idToken');
        if (idToken) {
          sessionStorage.setItem('jwtToken', JSON.stringify(idToken.value));
          sessionStorage.setItem(
            'user',
            JSON.stringify({
              signInUserSession: {
                idToken: {
                  jwtToken: idToken.value,
                  payload: {
                    ...idToken.claims
                  }
                }
              },
              username: idToken.claims.email
            })
          );
          // User already logged in
          console.info(`Hi ${idToken.claims.email}!`);
          await this.getOktaUser();
        } else {
          this.authClient
            .signInWithCredentials({ username, password })
            .then((transaction) => {
              if (
                transaction.status === 'SUCCESS' ||
                transaction.status === 'MFA_REQUIRED' ||
                transaction.status === 'MFA_ENROLL'
              ) {
                this.authClient.token.getWithRedirect({
                  sessionToken: transaction.sessionToken,
                  responseType: 'id_token'
                });
              }
            })
            .catch((error) => {
              console.error(error);
              return error;
            });
        }
      }
    }
  };

  getOktaUser = async () => {
    fetch(process.env.REACT_APP_BASEURL + 'auth/oidc/users', {
      method: 'POST',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Authorization:
          'Bearer ' + JSON.parse(sessionStorage.getItem('jwtToken'))
      }
    })
      .then((response) => response.json())
      .then(async (uData) => {
        if (uData.success) {
          sessionStorage.setItem('parentAccountName', uData.parentAccount.name);
          sessionStorage.setItem('parentAccountId', uData.parentAccount.id);
          await this.getPermissionsAfterOkta();
        } else {
          console.error(uData.message);
        }
      })
      .catch((error) => {
        if (error && error.response) {
          console.error(error.response);
        }
      });
  };

  getPermissionsAfterOkta = async () => {
    fetch(process.env.REACT_APP_BASEURL + 'getRolesPermissions', {
      method: 'GET',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Authorization:
          'Bearer ' + JSON.parse(sessionStorage.getItem('jwtToken'))
      }
    })
      .then((response) => response.json())
      .then((Permissons) => {
        if (Permissons.permission) {
          const encryptedString = cryptr.encrypt(
            JSON.stringify(Permissons.permission)
          );
          localStorage.setItem('permissions', encryptedString);
          let session = {
            _id: uuidGenerator(),
            _name: 'Kiosk Admin Login',
            _session_active: true,
            _start_date: new Date(),
            _end_date: null
          };
          const encryptedSessionObject = _cryptr.encrypt(
            JSON.stringify(session)
          );
          localStorage.setItem('ActiveSession', encryptedSessionObject);
        }

        // *** MaheshKumar Soni: Check if you need these permission here and you should have permission enum for these code block which is not available currently! *** //

        // if (Permissons.user) {
        //   let roles;
        //   if (Permissons.user.roles.length > 0) {
        //     sessionStorage.setItem("permissionUser", JSON.stringify(Permissons.user.roles[0].id));
        //     roles = Permissons.user.roles;
        //     roles.forEach(role => {
        //       if (role.id === USER_ROLES.PHARMACIST) {
        //         sessionStorage.setItem('roleId', JSON.stringify(role.id));
        //       }
        //       if (role.id === USER_ROLES.ADMIN) {
        //         sessionStorage.setItem('admin', JSON.stringify(role.id));
        //       }
        //       if (role.id === USER_ROLES.SUPERADMIN) {
        //         sessionStorage.setItem('superadmin', JSON.stringify(role.id));
        //       }
        //       if (role.id === USER_ROLES.DELIVERYTECH) {
        //         sessionStorage.setItem('deliverytech', JSON.stringify(role.id))
        //       }
        //     });
        //   }
        // }

        if (Permissons.superAdmin) {
          localStorage.setItem('permissionUser', Permissons.superAdmin);
        }
        this.props.history.push('/AdminPanel');
      })
      .catch((error) => {
        console.error(error);
        return error;
      });
  };

  handleOktaLogin = (formData) => {
    if (!formData.username) {
      this.setState({ usernameError: true });
    } else {
      this.setState({ usernameError: false });
      fetch(process.env.REACT_APP_BASEURL + 'auth/oidc/' + formData.username, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
        .then((response) => {
          try {
            return response.json();
          } catch (error) {
            return null;
          }
        })
        .then((uData) => {
          if (uData.success) {
            this.setState({ instruction: 'Welcome', usernameError: false });
            const issuer = uData.issuer || uData.parentAccount.issuer;
            const clientId = uData.clientId || uData.parentAccount.client_id;
            sessionStorage.setItem('issuer', issuer);
            sessionStorage.setItem('clientId', clientId);
            this.auth(formData.username, formData.password, issuer, clientId);
          } else {
            this.setState({
              instruction: uData.message,
              usernameError: true
            });
          }
        })
        .catch((error) => {
          if (error && error.response) {
            console.log(error.response);
          }
        });
    }
  };

  render() {
    const { classes, intl } = this.props;
    const validationSchema = Yup.object({
      username: Yup.string(intl.formatMessage(messages.EnterEmail))
        .email(intl.formatMessage(messages.EnterValidEmail))
        .required(intl.formatMessage(messages.EmailRequired)),
      password: Yup.string(intl.formatMessage(messages.EnterPassword))
        .min(8, intl.formatMessage(messages.PasswordEightChar))
        .matches(/[A-Z]/, intl.formatMessage(messages.PasswordOneUpper), true)
        .matches(/[a-z]/, intl.formatMessage(messages.PasswordOneLower), true)
        .matches(/[0-9]/, intl.formatMessage(messages.PasswordOneNumber), true)
        .required(intl.formatMessage(messages.PasswordRequired))
    });
    return (
      <>
        <AdminHeader myLogoheight="300" />
        <div className={classNames(classes.mainContainer)}>
          <div className={classNames(classes.container)}>
            <div className={classNames(classes.appLogoContainer)}>
              <img
                className={classNames(classes.appLogo)}
                alt="app-logo"
                src="/images/brands/Steps/Welcome-Line.png"
              />
            </div>
            <div>
              <span className={classNames(classes.appName)}>Sign In</span>
              &nbsp;
              <span className={classNames(classes.title)}>with Okta</span>
            </div>
            {this.state.isLoader === true ? (
              <div className={classes.spinner}>
                <img
                  src={spinner}
                  alt="spinner"
                  className={classes.spinnerImage}
                />
              </div>
            ) : null}
            <div>
              <div className={classNames(classes.leftFormContent)}>
                <div className={classNames(classes.form)}>
                  <Formik
                    className={classNames(classes.formContainer)}
                    render={(props) => (
                      <OktaLoginFormControls
                        {...props}
                        handleBack={this.handleback}
                        handleusername={this.binduser}
                      />
                    )}
                    validationSchema={validationSchema}
                    initialValues={{ username: '', password: '' }}
                    onSubmit={(formData, formActions) => {
                      this.handleOktaLogin(formData);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <KioskIdelScreen />
        <KioskFooter customFooterBottom="0%" isLanguageBarAllowd={true} />
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  injectIntl(withRouter(OktaLoginForm))
);
